<template>
  <div class="leads-unsubscribed-from-all-page-wrapper">

    <b-notification class="notification-wrapper" :active.sync="notification.show" :type="notification.type" auto-close>
      {{ notification.text }}
    </b-notification>

    <div class="component-title">
      {{ $t('leads.leads_unsubscribed_from_all_mailings') }}
    </div>

    <div class="unsubscribe-lead-wrapper">
      <div class="block-title">
        {{ $t('leads.unsubscribe_lead_from_all_mailings') }}
      </div>

      <div class="block-control">
        <div class="block-input-wrapper">
          <b-field :type="email.error ? 'is-danger' : 'text'">
            <b-input v-model="email.value" @input="email.error = false" :placeholder="$t('users.enter_email')"></b-input>
          </b-field>
          <div class="error-message" v-if="email.error">
            {{ $t('analytics.invalid_email') }}
          </div>
        </div>

        <div class="unsubscribe-lead-button-wrapper">
          <b-button type="is-primary" @click="unsubscribeLead" :loading="isLoading">
            {{ $t('leads.unsubscribe') }}
          </b-button>
        </div>
      </div>
    </div>

    <div class="unsubscribed-emails-table-wrapper">
      <div class="block-title">
        {{ $t('leads.list_of_leads_unsubscribed_from_all_mailings') }}
      </div>

      <b-table :data="unsubscribed_emails" :loading="is_loading_table" 
        paginated
        backend-pagination
        :total="total_pages"
        :current-page.sync="request_params.page"
        :per-page="request_params.per_page"
        @page-change="onPageChange"

        backend-sorting
        :default-sort-direction="request_params.sort_direction"
        :default-sort="[request_params.sort_field, request_params.sort_direction]"
        @sort="onSort"
      >
        <template slot-scope="props">
            <b-table-column field="email" label="Почта" sortable>
              {{ props.row.email }}
            </b-table-column>

            <b-table-column field="time_added" label="Дата создания" sortable centered>
              {{ formatDate(props.row.time_added) }}
            </b-table-column>
        </template>
        <template slot="empty">
          <div class="content has-text-grey has-text-centered empty-table" v-if="!is_loading_table">
            {{ $t('no_data') }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

export default {
  name: 'LeadsUnsubscribedFromAll',
  data () {
    return {
      email: {
        value: '',
        error: false
      },
      notification: {
        show: false,
        text: '',
        type: 'is-success'
      },
      isLoading: false,
      request_params: {
        page: 1,
        per_page: 10,
        sort_field: 'email',
        sort_direction: 'asc',
      },
      total_pages: 10,
      unsubscribed_emails: [],
      is_loading_table: false,
    }
  },
  created () {
    this.getUnsubscribedLeads()
  },
  methods: {
    validateEmail () {
      let match = this.email.value.match(this.$store.getters.regex.email)
      if(match && match.length > 0) {
        return true
      }
      else {
        return false
      }
    },
    unsubscribeLead () {
      if(this.validateEmail()) {
        this.isLoading = true
        this.$store.dispatch('unsubscribeLeadRequest', {
          vm: this,
          callback: this.unsubscribeLeadCallback
        })
      }
      else {
        this.email.error = true
      }
    },
    unsubscribeLeadCallback (response) {
      this.isLoading = false
      if(response === 'error') {
        this.notification.type = 'is-danger'
        this.notification.text = this.$t('leads.lead_unsubscribe_error')
        this.notification.show = true
      }
      else {
        this.notification.type = 'is-success'
        this.notification.text = this.$t('leads.lead_successfully_unsubscribed')
        this.notification.show = true

        this.email.value = ''

        this.getUnsubscribedLeads()
      }
    },

    getUnsubscribedLeads () {
      this.is_loading_table = true
      this.$store.dispatch('getUnsubscribedLeadsRequest', {
        vm: this,
        callback: this.getUnsubscribedLeadsRequestCallback
      })
    },
    getUnsubscribedLeadsRequestCallback (response) {
      if(response === 'error') {
        this.notification.type = 'is-danger'
        this.notification.text = this.$t('leads.error_getting_leads_unsubscribed_from_all_mailings')
        this.notification.show = true
      }
      else {
        this.unsubscribed_emails = response.unsubscribed_emails
        this.total_pages = response.pagination.total_pages * this.request_params.per_page
      }

      this.is_loading_table = false
    },
    onPageChange (page) {
      this.request_params.page = page
      this.getUnsubscribedLeads()
    },
    onSort (field, order) {
      this.request_params.sort_field = field
      this.request_params.sort_direction = order
      this.getUnsubscribedLeads()
    },
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
  }
}
</script>

<style lang="scss">
.leads-unsubscribed-from-all-page-wrapper {
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #dbdbdb;

  .notification-wrapper {
    position: fixed;
    top: 82px;
  }
  .component-title {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .unsubscribe-lead-wrapper {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 30px;
    padding-bottom: 20px;

    .block-title {
      margin-bottom: 10px;
    }
    .block-control {
      display: flex;

      .block-input-wrapper {
        .field {
          width: 265px;
        }
      }
      .unsubscribe-lead-button-wrapper {
        margin-left: 20px;
      }
    }
  }
  .unsubscribed-emails-table-wrapper {

    .block-title {
      margin-bottom: 10px;
    }
    .empty-table {
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .table-wrapper {
      min-height: 100px;
    }
  }
}
</style>