<template>
  <div class="leads-page-wrapper">
    <leads-unsubscribed-from-all></leads-unsubscribed-from-all>
  </div>
</template>

<script>
import LeadsUnsubscribedFromAll from '../components/LeadsUnsubscribedFromAll'

export default {
  name: 'Leads',
  components: {
    LeadsUnsubscribedFromAll,
  }
}
</script>